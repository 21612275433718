import './App.css';
import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Survey from './Components/Survey.js';
import catHappy from '../src/Assets/happycat.json'
import Parrot from '../src/Assets/parrot.json'
import { Player } from "@lottiefiles/react-lottie-player";


import 'bootstrap/dist/css/bootstrap.min.css';


function App() {
  return (
    <div>
    <BrowserRouter>
    <Routes>
        <Route index element={<Survey />} />
    </Routes>
  </BrowserRouter>
  <div>
    <Player className="happyCat" autoplay loop src={catHappy}></Player>
  </div> 
  <div>
    <Player className="parrot" autoplay loop src={Parrot}></Player>
  </div> 
  </div>
  );
}

export default App;
