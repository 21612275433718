import React from 'react';
import '../Style/Survey.css';
import { Container, Row, Col, Tabs, Tab, Nav } from 'react-bootstrap';
import headerImage from "../Assets/headerImage.svg";
import Dogs from "../Assets/dogs.svg";
import Cats from "../Assets/cats.svg";
import Fish from "../Assets/fish.svg";
import Birds from "../Assets/birds.svg";
import Guineapig from "../Assets/Guineapig.svg";
import earth from "../Assets/earth.svg";
import cutepets from "../Assets/cutepets.svg";
import Doctor from "../Assets/doctors.svg";
import PetOwners from "../Assets/petowners.svg";
import PetShops from "../Assets/petshops.svg";
import Ngo from "../Assets/ngo.svg";
import mView from "../Assets/mView.svg";
import { Swiper, SwiperSlide } from "swiper/react";
// import { Player } from "@lottiefiles/react-lottie-player";


// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation, Autoplay } from "swiper";
function Survey() {

    return (
        <div>
            <section className='header_image'>
                <div>
                    <img src={headerImage} className="img_fluid desktop_visible"></img>
                    <img src={mView} className='img-fluid mobile_visible'></img>
                </div>
            </section>
            <section className='about_container'>
                <Container fluid>
                    <div className='card_container'>
                        <Row>
                            <Col xs={12} md={4} xl={4} lg={4}>
                                <div className='text_container'>
                                    <h1 className='creative_heading'>Little Paws, Big impacts</h1>
                                    <p className='paragraph'>Hey Folks!!!! I have been thinking about this lately that people around us are changing towards animals. Every other person is a pet parent, every other person rescues the animal and every other person wants to take care. But then I also thought about the idea that, everyone wishes to do but very few manages to get success in it. Here the case is, we all have intentions but because of less knowledge of resources we can't take adequate steps. I and my friend, came with a solution. The solution is to connect all the resources with help seekers. We are trying to connect four windows all together: Pet owners, Veterans, Animal store and NGOs. </p>
                                </div>
                            </Col>
                            <Col xs={12} md={8} xl={8} lg={8}>
                                <Swiper className="customSlider"
                                    // slidesPerView={3}
                                    spaceBetween={24}
                                    // pagination={{
                                    //     clickable: true,
                                    // }}
                                    autoplay={{
                                        delay: 2500,
                                        disableOnInteraction: false,
                                    }}
                                    navigation={true}
                                    breakpoints={{
                                        640: {
                                            slidesPerView: 2,
                                            spaceBetween: 20,
                                        },
                                        768: {
                                            slidesPerView: 3,
                                            spaceBetween: 40,
                                        },
                                        1024: {
                                            slidesPerView: 3,
                                            spaceBetween: 50,
                                        },
                                    }}
                                    modules={[Navigation, Autoplay]}
                                >
                                    <SwiperSlide>
                                        <div className='card_petname'>
                                            <img src={Dogs} className="img-fluid card_main_pet"></img>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className='card_petname'>
                                            <img src={Cats} className="img-fluid card_main_pet"></img>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className='card_petname'>
                                            <img src={Fish} className="img-fluid card_main_pet"></img>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className='card_petname'>
                                            <img src={Birds} className="img-fluid card_main_pet"></img>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className='card_petname'>
                                            <img src={Guineapig} className="img-fluid card_main_pet"></img>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className='card_petname'>
                                            <img src={Cats} className="img-fluid card_main_pet"></img>
                                        </div>
                                    </SwiperSlide>
                                </Swiper>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
            <section className='surveyContainer'>
                <Container fluid>
                    <div className='text_container mb0'>
                        <h1 className='creative_heading centerAlign'>Fill Out Our Survey Form</h1>
                        <p className='paragraph centerAlign'>I and my friend, came with a solution. The solution is to connect all the resources with help seekers. We are trying to connect four windows all together: Pet owners, Veterans, Animal store and NGOs. </p>
                    </div>
                    <div className='tabsSurvey'>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="doctors">
                            <Nav variant="pills" className="flex-column">
                                <Row>
                                    <Col xs={6} md={3} xl={3} lg={3}>
                                        <Nav.Item>
                                            <Nav.Link className='styleTabs' eventKey="doctors">For Doctors</Nav.Link>
                                        </Nav.Item>
                                    </Col>
                                    <Col xs={6} md={3} xl={3} lg={3}>
                                        <Nav.Item>
                                            <Nav.Link className='styleTabs' eventKey="petowners">For Pet Owners</Nav.Link>
                                        </Nav.Item>
                                    </Col>
                                    <Col className='mt28' xs={6} md={3} xl={3} lg={3}>
                                        <Nav.Item>
                                            <Nav.Link className='styleTabs' eventKey="petshops">For Pet Shops</Nav.Link>
                                        </Nav.Item>
                                    </Col>
                                    <Col className='mt28' xs={6} md={3} xl={3} lg={3}>
                                        <Nav.Item>
                                            <Nav.Link className='styleTabs' eventKey="ngo">For NGO's</Nav.Link>
                                        </Nav.Item>
                                    </Col>
                                </Row>
                            </Nav>
                            <div className='mt42'>
                                <Tab.Content>
                                    <Tab.Pane eventKey="doctors">
                                        <Row>
                                            <Col xs={12} md={6} xl={6} lg={6}>
                                                <div className='surveyImage'>
                                                    <img src={Doctor} className="img-fluid imgMainSurvey"></img>
                                                </div>
                                            </Col>
                                            <Col xs={12} md={6} xl={6} lg={6}>
                                                <div className='surveyTextBlock mt82'>
                                                    <h2 className='surveyHead'>Survey Form For Doctors</h2>
                                                    <p className='surveyPara'>Hello Doctor, Hello Savior !!!
We took an initiative to connect all the pet owners with their knight in shining armour. As at the time of emergency or even when the pet the is not doing well, the owners get anxious and tensed for their pet's condition. Here is an fair try to connect all the doctors and pet owners in one window.
Here are few questions, for being our rescuer.</p>
                                                </div>
                                                <div className='mt32'>
                                                    <a href='https://docs.google.com/forms/d/e/1FAIpQLScg_9hnZGxAE4YKpKUwVNUKw3Dddns8sMm3utpvLuriWUelWg/viewform?usp=sf_link' target="_blank" className='btnSurvey'>Visit Now</a>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="petowners">
                                        <Row>
                                            <Col xs={12} md={6} xl={6} lg={6}>
                                                <div className='surveyImage'>
                                                    <img src={PetOwners} className="img-fluid imgMainSurvey"></img>
                                                </div>
                                            </Col>
                                            <Col xs={12} md={6} xl={6} lg={6}>
                                                <div className='surveyTextBlock mt82'>
                                                    <h2 className='surveyHead'>Survey Form For Pet Owners</h2>
                                                    <p className='surveyPara'>Hello Pet parents!!
"Pets are humanizing. They remind us we have an obligation and responsibility to preserve and nurture and care for all life" -James Cromwell
We may require your nearly 5 minutes for our upcoming project which will be solely for your child i.e your pet ; Your most loyal friend.</p>
                                                </div>
                                                <div className='mt32'>
                                                    <a href='https://docs.google.com/forms/d/e/1FAIpQLSfpJ0rmdV6QzP3_1dNezqPUeLVwdNn1jfv_xhbY25ShMUFNdQ/viewform?usp=sf_link' target="_blank" className='btnSurvey'>Visit Now</a>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="petshops">
                                        <Row>
                                            <Col xs={12} md={6} xl={6} lg={6}>
                                                <div className='surveyImage'>
                                                    <img src={PetShops} className="img-fluid imgMainSurvey"></img>
                                                </div>
                                            </Col>
                                            <Col xs={12} md={6} xl={6} lg={6}>
                                                <div className='surveyTextBlock mt82'>
                                                    <h2 className='surveyHead'>Survey Form For Pet Shops</h2>
                                                    <p className='surveyPara'>Hey Entrepreneur!!
I Hope you  are breaking the bank! We have took an initiative to connect all the unreachable and non-connected customers with your store. We wish you to strike gold by each passing day. 
Here are a few questions for you all.</p>
                                                </div>
                                                <div className='mt32'>
                                                    <a href='https://docs.google.com/forms/d/e/1FAIpQLSe9kas19R7xi_l3hL8lRHS10eD2N2HVtrSku4vFklOSlZrgqA/viewform?usp=sf_link' target="_blank" className='btnSurvey'>Visit Now</a>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="ngo">
                                        <Row>
                                            <Col xs={12} md={6} xl={6} lg={6}>
                                                <div className='surveyImage'>
                                                    <img src={Ngo} className="img-fluid imgMainSurvey"></img>
                                                </div>
                                            </Col>
                                            <Col xs={12} md={6} xl={6} lg={6}>
                                                <div className='surveyTextBlock mt82'>
                                                    <h2 className='surveyHead'>Survey Form For NGO's</h2>
                                                    <p className='surveyPara'>We all know its hard to be hungry and homeless. Remember when we moved out from our houses for studies or job  we always wished someone to adopt us ( Like literally :D). Somehow we managed our survivals but the pets on streets, forbidden pets and medically ill pets could not. We took an initiative to connect all the NGO with the general public. 
If we can not provide them home, at least we can connect the dots to the shelters. </p>
                                                </div>
                                                <div className='mt32'>
                                                    <a href='https://docs.google.com/forms/d/e/1FAIpQLSd4BSpoWnzQyNInQcZ96vEJsrwQM0j_nVJRYU7ttnjninGdzg/viewform?usp=sf_link' target="_blank" className='btnSurvey'>Visit Now</a>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                        </Tab.Container>
                    </div>
                </Container>
            </section>
            <section className='third_div'>
                <Container fluid>
                    <div className='text_div'>
                        <p className='info_2'>Such short little lives our pets have to spend with us, and they spend most of it waiting for us to come home each day.
It is amazing how much love and laughter they bring into our lives and even how much closer we become with each other because of them.</p>
                    </div>

                </Container>
                <div className='prelative'>
                    <img src={cutepets} className="group_svg"></img>
                    <img id="rotate" src={earth} className="earth"></img>
                </div>
            </section>
        </div>
    )
}

export default Survey